var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { allCountriesOptions, genderOptions } from "../../data";
import AsyncStatus from "../../utils/async";
import { createUserEmailAddressDoesntRequireActivation, createUserEmailAddressAvailableOrRequiresActivation, } from "../../validation/userEmailAddressAvailable";
import createValidAgentCode from "../../validation/validAgentCode";
import NotificationsMixin from "./NotificationsMixin";
import mixins from "vue-typed-mixins";
import resendStudentWelcome from "../../api/mutations/resendStudentWelcome";
import moment from "moment";
export default mixins(NotificationsMixin).extend({
    props: {
        value: {
            type: Object,
            required: true,
        },
        showSubmit: {
            type: Boolean,
            default: true,
        },
        showSaveForNextTime: {
            type: Boolean,
            default: false,
        },
        submitLabel: {
            type: String,
            default: "Next", // TODO: $t('Next')
        },
        submitWorkingLabel: {
            type: String,
            default: "Next", // TODO: $t('Next')
        },
        extraFields: {
            type: Array,
            required: true,
            // Validator making TypeScript fail for some reason
            /*validator(value: ReadonlyArray<string>): boolean {
              return value.every((item: string): boolean => {
                return ["mobile", "health", "sign-up"].includes(item)
              });
            }*/
        },
        showAreYouAnAgent: {
            type: Boolean,
            required: true,
        },
    },
    data: function () {
        return {
            countryOptions: allCountriesOptions,
            genderOptions: genderOptions,
            status: new AsyncStatus(),
        };
    },
    created: function () {
        this.$validator.extend("userEmailAddressDoesntRequireActivation", createUserEmailAddressDoesntRequireActivation(this.$apollo, this.$t.bind(this), ""));
        this.$validator.extend("userEmailAddressAvailableOrRequiresActivation", createUserEmailAddressAvailableOrRequiresActivation(this.$apollo, this.$t.bind(this)));
        this.$validator.extend("agentCode", createValidAgentCode(this.$apollo, this.$t.bind(this)));
    },
    computed: {
        futureOnlyDisabledDates: function () {
            return { to: moment().subtract(1, "day") };
        },
    },
    methods: {
        onResendUserEmail: function () {
            var _this = this;
            this.$apollo
                .mutate(resendStudentWelcome(this.value.emailAddress))
                .then(function () {
                return _this.displaySuccessMessage(_this.$t("Welcome email has been re-sent"));
            });
        },
        studentFieldLabel: function (base) {
            if (this.value.purchasingAgent) {
                return "".concat(this.$t("Student's"), " ").concat(base);
            }
            return base;
        },
        onAreYouAnAgentChange: function (value) {
            if (value) {
                this.onFormChange({
                    purchasingAgent: { code: "", emailAddress: "" },
                    saveForNextTime: false,
                });
                return;
            }
            this.onFormFieldChange("purchasingAgent", undefined);
        },
        onPurchasingAgentFieldChange: function (fieldName, value) {
            var _a;
            this.onFormFieldChange("purchasingAgent", __assign(__assign({}, this.value.purchasingAgent), (_a = {}, _a[fieldName] = value, _a)));
        },
        onFormFieldChange: function (fieldName, value) {
            var _a;
            this.onFormChange((_a = {}, _a[fieldName] = value, _a));
        },
        onFormChange: function (updates) {
            this.$emit("input", __assign(__assign({}, this.value), updates));
        },
        submit: function () {
            var _this = this;
            this.status.run(this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                _this.$emit("submit");
            }));
        },
        doesExtraIncludeOneOf: function (fields) {
            var _this = this;
            return fields.some(function (field) {
                return _this.extraFields.includes(field);
            });
        },
    },
});
