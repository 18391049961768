var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
export default (function (apollo, $t) { return ({
    getMessage: function () {
        return $t("This agent code doesn't exist").toString();
    },
    validate: function (code) {
        if (!code) {
            return Promise.resolve(true);
        }
        return apollo
            .query({
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          query AgentCodeExistsQuery($code: String!) {\n            agentCodeExists(code: $code)\n          }\n        "], ["\n          query AgentCodeExistsQuery($code: String!) {\n            agentCodeExists(code: $code)\n          }\n        "]))),
            variables: { code: code },
        })
            .then(function (response) {
            return response.data.agentCodeExists;
        });
    },
}); });
var templateObject_1;
